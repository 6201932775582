import { SVGProps } from 'react';

export const SvgHolaglowHand = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={132}
    height={120}
    fill="currentColor"
    viewBox="0 0 132 120"
    {...props}
  >
    <path d="M127.763 37.7246C104.019 77.3555 103.174 76.0645 67.8666 1.31086C66.7123 -1.14814 62.9817 0.142829 63.6 2.78626C77.4714 62.6425 69.9689 61.7204 30.7251 2.66331C29.1792 0.347751 25.5929 2.31495 26.7677 4.83543C57.3754 71.8842 64.2802 80.6342 12.9788 29.6919C11.0413 27.7861 7.99084 30.2451 9.47485 32.5197C48.8836 93.1546 44.6995 95.2448 3.72431 62.3146C1.74563 60.7163 -0.99567 63.0933 0.364673 65.2654C33.8374 118.749 85.6747 145.818 116.344 86.2489C122.507 74.2818 127.825 58.8105 131.947 39.3434C132.462 36.8435 129.082 35.5115 127.763 37.7246Z" />
  </svg>
);
