'use client';

import { ComponentType, useEffect, useState } from 'react';

export type IconFamily =
  | 'categoryIcons'
  | 'dermaIcons'
  | 'designSystemIcons'
  | 'oldIcons'
  | 'serviceIcons'
  | 'socialIcons'
  | 'suggestionIcons';

export default function DynamicIcon({
  name,
  family = 'designSystemIcons',
  className = '',
}: {
  name: string;
  family?: IconFamily;
  className?: string;
}) {
  const [IconComponent, setIconComponent] = useState<ComponentType<any> | null>(
    null
  );

  useEffect(() => {
    const loadIcon = async () => {
      try {
        const IconModule = await import(`app/icons/${family}/${name}`);
        setIconComponent(() => IconModule[name]);
      } catch (error) {
        console.error(
          `Icon "${name}" in folder "${family}" could not be loaded.`,
          error
        );
      }
    };

    loadIcon();
  }, [name, family]);

  if (!IconComponent) {
    return null;
  }

  return <IconComponent className={className} />;
}
