'use client';

import { Button } from 'designSystem/Buttons/Buttons';
import { Container, Flex } from 'designSystem/Layouts/Layouts';
import { Text } from 'designSystem/Texts/Texts';
import Image from 'next/image';

export default function TrueHairHomeHero() {
  return (
    <div className="relative">
      <Container className="md:py-16 flex flex-col md:flex-row">
        <div className="md:absolute top-0 bottom-0 right-0 left-1/2">
          <div className="relative h-full bg-hg-black/5 rounded-l-[75px] overflow-hidden">
            <Image
              src="/images/truehair/hero/bg-desk.webp"
              fill
              alt="Fórmula anticaída definitiva Truehair"
              className="object-cover"
            />
          </div>
        </div>
        <div className="flex md:w-1/2 mr-auto">
          <Flex layout="col-left" className="pt-24 md:pb-24">
            <Text as="h1" className="font-bold text-4xl mb-2 md:text-5xl">
              La fórmula anticaída definitiva
            </Text>
            <p className="mb-6">
              Frena la caída y luce un pelo más denso y grueso
            </p>

            <Button
              type="truehair"
              size="xl"
              customStyles="text-[18px] py-8"
              onClick={() => {
                const scrollElement =
                  document.getElementById('truehairFormula');

                if (scrollElement) {
                  scrollElement.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
                }
              }}
            >
              Ver fórmula
            </Button>
          </Flex>
        </div>
      </Container>
      <Image
        src="/images/truehair/hero/bg.webp"
        width={780}
        height={780}
        alt="Fórmula anticaída definitiva Truehair"
        className="md:hidden w-full"
      />
    </div>
  );
}
