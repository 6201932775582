'use client';

import { useEffect } from 'react';
import { useSessionStore } from 'app/stores/globalStore';

export default function ConversionUrlSaver({ url = '' }: { url?: string }) {
  const { analyticsMetrics, setAnalyticsMetrics } = useSessionStore(
    state => state
  );

  useEffect(() => {
    analyticsMetrics.conversionUrl = url;
    setAnalyticsMetrics(analyticsMetrics);
  }, []);

  return <></>;
}
