import { SVGProps } from 'react';

export const SvgStar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={16}
    fill="currentColor"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M9.72308 1.3494C9.33243 0.557655 8.73513 0 7.99791 0C7.26145 0 6.66223 0.556741 6.26781 1.34795L6.26738 1.34883L4.94242 4.02064L4.94073 4.02408C4.89209 4.12422 4.78481 4.25195 4.63337 4.36516C4.48213 4.47823 4.33028 4.54435 4.22379 4.56276L4.22311 4.56287L1.82329 4.96488C0.971961 5.10794 0.288504 5.52027 0.0691325 6.21293C-0.149671 6.90379 0.168388 7.63688 0.776473 8.24999L2.64346 10.1324C2.73142 10.2211 2.82084 10.377 2.87589 10.5709C2.93066 10.7638 2.93759 10.9457 2.90997 11.0715L2.90968 11.0728L2.37589 13.3999C2.15569 14.3594 2.24312 15.2648 2.8775 15.7315C3.51428 16.2 4.40175 16.007 5.24426 15.5029L7.49166 14.1615L7.49291 14.1608C7.61124 14.0918 7.79452 14.044 8.00167 14.044C8.20998 14.044 8.39013 14.0923 8.50278 14.1596L10.7539 15.5032C11.5979 16.0058 12.4863 16.2024 13.1229 15.7345C13.7575 15.268 13.8415 14.3611 13.6219 13.4003L13.088 11.0728L13.0877 11.0715C13.0601 10.9457 13.067 10.7638 13.1218 10.5709C13.1769 10.377 13.2663 10.2211 13.3542 10.1324L15.2201 8.25113L15.2208 8.25043C15.8327 7.63735 16.1516 6.90378 15.9311 6.21208C15.7104 5.5198 15.0255 5.1079 14.1746 4.96492L11.7732 4.56263C11.6624 4.54402 11.5085 4.47733 11.3569 4.36451C11.2051 4.25159 11.098 4.12407 11.0494 4.02408L9.72308 1.3494Z" />
  </svg>
);
